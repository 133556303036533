import React from 'react'

import WineSection from './WineSection'
import GlassBottleSection from './GlassBottleSection'

const WineByTheGlass = (props) => {
  // filters all menu items for wbtg category items, then by section
  const wineByTheGlass = props.mainMenuData.filter(
    (item) => item.category === 'Wines by the Glass'
  )
  const sparkling = wineByTheGlass.filter(
    (item) => item.section === 'Sparkling'
  )
  const white = wineByTheGlass.filter((item) => item.section === 'White')
  const red = wineByTheGlass.filter((item) => item.section === 'Red')
  const sangria = wineByTheGlass.filter((item) => item.section === 'Sangría')
  // loads wbtg specific restrictions for footer
  const restrictions = props.restrictions && props.restrictions.wbtg

  return (
    <div className='main'>
      <h1 style={{ marginBottom: '0px' }}>{props.title}</h1>
      <h3
        style={{
          marginTop: '8px',
          marginBottom: '40px',
          padding: '0 20px 0 20px',
          lineHeight: '20px',
          color: '#86090f',
          fontSize: '1.17rem'
        }}
      >
        {props.subtitle}
      </h3>
      <WineSection title='Sparkling' wines={sparkling} />
      <WineSection title='White' wines={white} />
      <WineSection title='Red' wines={red} />
      <GlassBottleSection title='Sangría' wines={sangria} />
      <h4 className='menu-may-vary'>Menu selection may vary by location.</h4>
      <div className='line-break'></div>
      <p className='wine-restrictions'>{restrictions}</p>
    </div>
  )
}

export default WineByTheGlass
