import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      colorPrimary: {
        color: "#86090F",
      }
    }
  }
});

const Loading = (props) => {

  return (
    <div className='loading'>
      <MuiThemeProvider theme={theme}>
        <CircularProgress />
        <p className='loading-text'>Loading...</p>
      </MuiThemeProvider>
    </div>
  );
}

export default Loading;
