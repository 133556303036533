import React from 'react'

import FlightRow from './FlightRow'

const Flights = (props) => {
  // filters all menu items for by category
  const flightsInfo = props.mainMenuData.filter(
    (item) => item.category === props.title
  )
  const flights = flightsInfo.map((flight, id) => {
    return <FlightRow key={id} flight={flight} />
  })
  // filter for the descrtiption I hid in the section for flights items
  const descriptions = flightsInfo.map((item) => item.section)
  const uniqueDescription = new Set(descriptions)
  const description = [...uniqueDescription]

  const restrictions = props.restrictions && props.restrictions.general

  return (
    <div className='main'>
      <h1 style={{ marginBottom: '0' }}>{props.title}</h1>
      <h3
        style={{
          marginTop: '8px',
          marginBottom: '0px',
          padding: '0 20px 0 20px',
          lineHeight: '20px',
          color: '#86090f',
          fontSize: '1.17rem'
        }}
      >
        {props.subtitle}
      </h3>
      <h4 className='flights-description margin0' style={{ color: 'black' }}>
        {description}
      </h4>
      <table className='flights-table'>{flights}</table>
      <h4 className='menu-may-vary'>Menu selection may vary by location.</h4>
      <div className='line-break'></div>
      <p className='wine-restrictions'>{restrictions}</p>
    </div>
  )
}

export default Flights
