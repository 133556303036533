// main imports
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import './App.css'
import './components/css/menu.css'
import './components/css/desktop.css'
// import ReactGa from 'react-ga' // old
import ReactGA from 'react-ga4'

// locally stored data
import { categoryRoutes, homeCategories } from './assets/localData/categories'
import { menuItems } from './assets/localData/menuItems'
import { conceptInfo, restrictions } from './assets/localData/additionalInfo'
import { specials } from './assets/localData/specials'

// component imports
import Header from './components/app-components/Header'
import Router from './components/app-components/Router'

class App extends Component {
  state = {
    // Shared Data from googleSheetsApi
    conceptInfo: [], // shared info for each concept page
    restrictions: {}, // category specific restrictions
    // Local & Shared Data
    categoryRoutes: {}, // set from a locally stored object
    homeCategories: [], // section/category structure on the nav page '/'
    categoryArray: [], // array of menu section names, also shared data
    // Queried Data
    menuItems: [], // menu data loaded from the main menu - Dynamic Menu on wp site
    selectedMenuCategory: '', // selection from the table of content pushes URL to selectedContent
    specialsList: []
  }

  //===== content functions =====

  setLocalData = async (
    categoryRoutes,
    menuItems,
    conceptInfo,
    restrictions
  ) => {
    // sets category routes obj and creates category array
    await this.setState({ categoryRoutes })
    await this.setState({ homeCategories })
    const categoryArray = Object.values(this.state.categoryRoutes)
    await this.setState({ categoryArray })
    // sets mainMenuData, concept info and restrictions
    await this.setState({ menuItems })
    await this.setState({ conceptInfo })
    await this.setState({ restrictions })
    await this.setState({ specialsList: specials })
  }

  // function controls changing content in the toggle and the corresponding URL
  handleSelectedMenuCategory = async (selectedMenuCategory) => {
    // set selected menu category
    await this.setState({ selectedMenuCategory })
    // set URL to appropriate menu category
    let obj = this.state.categoryRoutes
    let contentValue = this.state.selectedMenuCategory
    let pathname = Object.keys(obj).find((key) => obj[key] === contentValue)
    this.props.history.push({ pathname: `/${pathname}` })
    window.scrollTo(0, 0)
  }

  //===== refresh functions =====
  handleSelectedMenuCategoryOnRefresh = async () => {
    let key = window.location.pathname.substr(1)
    // for some reason, the below line gives the system time to set state again
    await this.setState({ categoryRoutes })
    let selectedMenuCategory = await Object.values(
      this.state.categoryRoutes
    ).find((value) => this.state.categoryRoutes[key] === value)
    await this.setState({ selectedMenuCategory })
    window.scrollTo(0, 0)
  }

  //===== functionality =====
  goBack = async () => {
    this.props.history.goBack()
    setTimeout(() => {
      this.handleSelectedMenuCategoryOnRefresh()
    }, 10)
  }

  //===== START =====

  componentDidMount = async () => {
    // google analytics -- OLD
    // ReactGa.initialize('UA-23014489-4')
    // ReactGa.pageview(window.location.pathname + window.location.search)
    // --------------------
    // NEW GA4
    ReactGA.initialize('G-Z9V9WVXF8P')
    ReactGA.send('pageview')
    // import local data stored in assets/data
    this.setLocalData(
      categoryRoutes,
      menuItems,
      conceptInfo,
      restrictions,
      specials
    )
    // functional
    this.handleSelectedMenuCategoryOnRefresh() // re-applies chosen values on refresh
  }

  render() {
    return (
      <div className='App'>
        <Header
          categoryArray={this.state.categoryArray}
          selectedMenuCategory={this.state.selectedMenuCategory}
          handleSelectedMenuCategory={this.handleSelectedMenuCategory}
          goBack={this.goBack}
        />
        <Router
          categoryArray={this.state.categoryArray}
          selectedMenuCategory={this.state.selectedMenuCategory}
          handleSelectedMenuCategory={this.handleSelectedMenuCategory}
          conceptInfo={this.state.conceptInfo}
          restrictions={this.state.restrictions}
          mainMenuData={this.state.menuItems}
          specialsList={this.state.specialsList}
          homeCategories={this.state.homeCategories}
        />
      </div>
    )
  }
}

export default withRouter(App)
