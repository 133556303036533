export const specials = [
  {
    title: '$6 Caipirinhas & Well-Cocktails',
    description:
      'Our signature cocktail and other house cocktails for only $6! Ask your server about our Caiprinha flavor options!',
    restrictions: 'This offer is not valid where the law prohibits happy hour.',
    image_url:
      'https://res.cloudinary.com/jadscloudinary/image/upload/v1608062321/SPECIALS-TDB-MENU-APP/Caipirinha_fqzoss.png',
    alt_text: 'Caipirinha Drink'
  },
  {
    title: '$4 Domestic Beers',
    description: 'Enjoy domestic beers for only $4 during happy hour!',
    restrictions: 'This offer is not valid where the law prohibits happy hour.',
    image_url: '',
    alt_text: ''
  },
  {
    title: '$7 Glasses of TdB Private Label Wine',
    description:
      'Wine-down with a $7 glass of one our many TdB private label wines, red and white!',
    restrictions: 'This offer is not valid where the law prohibits happy hour.',
    image_url: '',
    alt_text: ''
  },
  {
    title: '$8 House Martinis',
    description:
      'Come sip on a $8 martini made with house brands during Texas de Brazil happy hour!',
    restrictions: 'This offer is not valid where the law prohibits happy hour.',
    image_url: '',
    alt_text: ''
  }
]
