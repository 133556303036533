import React from 'react'

const BarApps = (props) => {
  // get all food items from menu data
  const barMenuItems = props.mainMenuData.filter(
    (item) => item.category === props.title
  )

  const barMenuImage = (
    <img
      src={'https://s3.amazonaws.com/tdb.online-images-secure/bar-menu.png'}
      alt={props.title}
      width='100%'
    />
  )

  const restrictions = props.restrictions && props.restrictions.food

  const barApps = barMenuItems.map((app, id) => {
    return (
      <div className='bar-item box-menu-item' key={id}>
        <h2 className='bar-app-name'>{app.name}</h2>
        <h4 className='bar-app-description'>{app.description}</h4>
        <h3 className='bar-app-price'>{app.price}</h3>
      </div>
    )
  })

  return (
    <div className='main'>
      <h1 className='bar-app-title' style={{ marginBottom: '40px' }}>
        {props.title}
      </h1>
      {/* <h3 className='cachaca-pricing' style={{ lineHeight: '25px' }}>
        Ask your server about pricing
      </h3> */}
      <div className='bar-apps-container'>{barApps}</div>
      {barMenuImage}
      <h4 className='menu-may-vary'>Menu selection may vary by location.</h4>
      <div className='line-break'></div>
      <p className='wine-restrictions'>{restrictions}</p>
    </div>
  )
}

export default BarApps
