import React from 'react';

import WineRow from './WineRow'

const WineSubSection = (props) => {

  //handles wine data to create wine rows
  const wines = props.wines.map((wine, id) => {
    return <WineRow key={id} wine={wine}/>
  })


  return (
    <>
      <h3 className='wine-sub-section-title'>{props.title}</h3>
      <table className='wine-sub-section'>{wines}</table>
    </>
  );
}

export default WineSubSection;
