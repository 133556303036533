import React from 'react'

import GlassOrBottle from './GlassOrBottle'

const GlassBottleSubSection = (props) => {
  return (
    <>
      <h2>{props.title}</h2>
      <GlassOrBottle title={props.wines[0].description} wines={props.wines} />
    </>
  )
}

export default GlassBottleSubSection
