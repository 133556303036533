import React from 'react'

import WineSection from './WineSection'

const NaBev = (props) => {
  const naBevCategory = props.mainMenuData.filter(
    (item) => item.category === 'NA Beverage'
  )
  const waters = naBevCategory.filter(
    (item) => item.section === 'Bottled Water'
  )
  const softDrinks = naBevCategory.filter(
    (item) => item.section === 'Soft Drinks'
  )
  const coffeeTea = naBevCategory.filter(
    (item) => item.section === 'Coffee & Tea'
  )

  const restrictions = props.restrictions && props.restrictions.general
  return (
    <div className='main'>
      <h1 style={{ marginBottom: '0' }}>Non-Alcoholic Beverages</h1>
      {/* <h3
        style={{
          marginTop: '8px',
          marginBottom: '40px',
          padding: '0 20px 0 20px',
          lineHeight: '20px',
          color: '#86090f',
          fontSize: '1.17rem'
        }}
      >
        {props.subtitle}
      </h3> */}
      <WineSection title='Bottled Water' wines={waters} />
      <WineSection title='Soft Drinks' wines={softDrinks} />
      <WineSection title='Coffee & Tea' wines={coffeeTea} />
      <h4 className='menu-may-vary'>Menu selection may vary by location.</h4>
      <div className='line-break'></div>
      <p className='wine-restrictions'>{restrictions}</p>
    </div>
  )
}

export default NaBev
