import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '60%',
    marginTop: '20px'
  },
  // helper text below
  root: {
    textAlign: 'center',
    fontSize: '12px',
    color: '#86090F',
  },
  select: {
    "&:after": {
      borderBottom: "solid 2px #86090F",
    },
    fontSize: '16px',
    fontFamily: 'Futura',
  }
}));

const ContentToggle = (props) => {

  const classes = useStyles();

  const contentOptions = props.categoryArray.map((content, id) => {
    return <option key={id} value={content}> {content} </option>
  })

  return (
    <div className='content-toggle'>
      <FormControl className={classes.formControl}>
        <NativeSelect
          className={classes.select}
          name="name"
          value={props.selectedMenuCategory}
          onChange={(e) => props.handleSelectedMenuCategory(e.target.value)}
        >
        {contentOptions}
        </NativeSelect>
        <FormHelperText className={classes.root}>Change Menu Category</FormHelperText>
      </FormControl>
    </div>
  );
}

export default ContentToggle;
