import React from 'react'
import MuiButton from './MuiButton'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
// import VipPromo from './VipPromo'
// import ButcherShopPromo from './ButcherShopPromo'
import logo from '../assets/images/logo.png'

const Home = (props) => {
  const navButtons = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
  const footerStyles = {
    marginTop: '30px',
    backgroundColor: 'white',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }

  const nav = props.homeCategories.map((section, id) => {
    const buttons = section.content.map((category, id) => {
      const imageSlug = category.replace('&', '%26').split(' ').join('+')
      const buttonStyling = {
        minHeight: '125px',
        maxHeight: '125px',
        minWidth: '150px',
        maxWidth: '150px',
        backgroundPosition: 'center',
        fontSize: '16px',
        backgroundImage: `url(https://s3.amazonaws.com/tdb.online-images-secure/nav/${imageSlug}.png)`
      }
      return (
        <MuiButton
          key={id}
          name={category}
          onClick={() => props.handleSelectedMenuCategory(category)}
          style={buttonStyling}
        />
      )
    })
    return (
      <div
        key={id}
        className='home-section-container'
        style={{ width: '90%', margin: '0 auto' }}
      >
        <p
          style={{
            margin: '20px 0 0 20px',
            textAlign: 'left',
            fontFamily: 'Futura'
          }}
        >
          {section.title}
        </p>
        <div style={navButtons} className='button-nav-container'>
          {buttons}
        </div>
      </div>
    )
  })

  return (
    <>
      {/* <VipPromo /> */}
      {/* <ButcherShopPromo /> */}
      {nav}
      <div className='footer' style={footerStyles}>
        <img className='logo' src={logo} alt='Texas de Brazil Logo' />
        <a
          href='https://texasdebrazil.com/'
          target='_blank'
          rel='noreferrer noopener'
        >
          <MuiButton
            name={'Visit texasdebrazil.com'}
            endIcon={<NavigateNextIcon />}
          />
        </a>
      </div>
    </>
  )
}

export default Home
