import React from 'react';

import WineRow from './WineRow'

const GlassOrBottle = (props) => {

  const wines = props.wines.map((wine, id) => {
    return <WineRow key={id} wine={wine}/>
  })

  return (
    <div className='wine-section'>
      <h4 className='glass-or-bottle'>{props.title}</h4>
      <table className='wine-sub-section'>{wines}</table>
    </div>
  );
}

export default GlassOrBottle;
