import React from 'react'

import GlassBottleSection from './GlassBottleSection'

const FortifiedWine = (props) => {
  // filters all menu items for by category, then by section
  const fortifiedWine = props.mainMenuData.filter(
    (item) => item.category === props.title
  )

  const port = fortifiedWine.filter((item) => item.section === 'Port')
  // const moscatel = fortifiedWine.filter(
  //   (item) => item.section === 'Moscatel de Setúbal'
  // )

  const restrictions = props.restrictions && props.restrictions.general
  // const moscatelDescription = props.restrictions && props.restrictions.moscatel
  const portDescription = props.restrictions && props.restrictions.port

  return (
    <div className='main'>
      <h3
        style={{
          marginTop: '8px',
          marginBottom: '0px',
          padding: '0 20px 0 20px',
          lineHeight: '20px',
          color: '#86090f',
          fontSize: '1.17rem'
        }}
      >
        {props.subtitle}
      </h3>
      <h1 style={{ marginBottom: '0' }}>Port</h1>
      <p className='fortified-wine-description'>{portDescription}</p>
      <GlassBottleSection title={'Port'} wines={port} />
      {/* <h1 style={{ marginBottom: '0', marginTop: '50px' }}>
        Moscatel de Setúbal
      </h1>
      <p className='fortified-wine-description'>{moscatelDescription}</p>
      <GlassBottleSection title={'Moscatel de Setúbal'} wines={moscatel} /> */}
      <h4 className='menu-may-vary'>Menu selection may vary by location.</h4>
      <div className='line-break'></div>
      <p className='wine-restrictions'>{restrictions}</p>
    </div>
  )
}

export default FortifiedWine
