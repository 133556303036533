import React from 'react'

const Specials = (props) => {
  const specialsItems = props.specialsList.map((special, id) => {
    return (
      <div className='specials-items' key={id}>
        <h2 className='specials-title'>{special.title}</h2>
        <h4 className='specials-description'>{special.description}</h4>
        <img
          className='dessert-image'
          src={special.image_url}
          alt={special.alt_text}
          width='100%'
        />
        <p className='specials-restrictions' style={{ fontSize: '12px' }}>
          {special.restrictions}
        </p>
      </div>
    )
  })

  return (
    <div className='main'>
      <h1 style={{ marginBottom: '20px' }}>Happy Hour Specials</h1>
      <h4
        className='menu-may-vary'
        style={{ marginTop: '10px', marginBottom: '50px' }}
      >
        Enjoy our happy hour specials from 4:30pm to 6:00pm
      </h4>
      {specialsItems}
      <h4 className='menu-may-vary'>
        Happy hour specials and hours may vary by location. Restrictions apply.
      </h4>
    </div>
  )
}

export default Specials
