import React from 'react'

const Desserts = (props) => {
  // get all food items from menu data

  const dessertMenuItems = props.mainMenuData.filter(
    (item) => item.category === 'Desserts'
  )
  const byTheSlice = dessertMenuItems.filter(
    (dessert) => dessert.inventory_id === 'By the slice'
  )
  const otherDesserts = dessertMenuItems.filter(
    (dessert) => dessert.inventory_id === 'Other Desserts'
  )

  const restrictions = props.restrictions && props.restrictions.food

  const byTheSliceDesserts = byTheSlice.map((dessert, id) => {
    let slug = dessert.name.replace(/ /g, '-') + '.png'
    let img = `https://s3.amazonaws.com/tdb.online-images-secure/desserts/${slug}`
    return (
      <div className='dessert-item' key={id}>
        <h2 className='dessert-name'>{dessert.name}</h2>
        <h3 className='bar-app-price'>{dessert.price}</h3>
        <p className='dessert-description'>{dessert.description}</p>
        <img
          className='dessert-image'
          src={img}
          alt={dessert.name}
          width='100%'
        />
      </div>
    )
  })

  const otherDessertItems = otherDesserts.map((dessert, id) => {
    return (
      <div className='dessert-item' key={id}>
        <h2 className='dessert-name'>{dessert.name}</h2>
        <h3 className='bar-app-price'>{dessert.price}</h3>
        <p className='other-dessert-description'>{dessert.description}</p>
      </div>
    )
  })

  return (
    <div className='main'>
      <h1 style={{ marginBottom: '0' }}>{props.title}</h1>
      <div className='dessert-container'>
        <h3 className='dessert-sub-title'>By the slice</h3>
        {byTheSliceDesserts}
        <h3 className='dessert-sub-title other-desserts'>Other Desserts</h3>
        {otherDessertItems}
      </div>
      <h4 className='menu-may-vary'>Menu selection may vary by location.</h4>
      <div className='line-break'></div>
      <p className='wine-restrictions'>{restrictions}</p>
    </div>
  )
}

export default Desserts
