import React from 'react';

const Concept = (props) => {

  // first two entries are the concept paragraphs, so slice those to leave restrictions
  const restrictions = props.conceptInfo.slice(2).map((sentence, id) => {
    return <p key={id}>{sentence}</p>
  })


  return (
    <div className='main'>
      <h1 className=''>Our Concept</h1>
      <p className='concept'><span className='tab'></span>{props.conceptInfo[0]}</p>
      <p className='concept'><span className='tab'></span>{props.conceptInfo[1]}</p>
      <img
        className='grilling-meats'
        src='https://s3.amazonaws.com/tdb.online-images-secure/Grill_1.png'
        alt='Grilling Meats'
        width='100%'/>
      <div className='line-break'></div>
      <div className='restrictions'>{restrictions}</div>
    </div>
  );
}

export default Concept;
