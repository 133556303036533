import React from 'react'

const Beers = (props) => {
  const beersCategory = props.mainMenuData.filter(
    (item) => item.category === 'Beers'
  )
  const beers = beersCategory.filter((item) => item.section === '')
  const beerNames = beers.map((item, id) => {
    return <p key={id}>{item.name}</p>
  })

  const restrictions = props.restrictions && props.restrictions.general
  return (
    <div className='main'>
      <h1 style={{ marginBottom: '0px' }}>Beers</h1>
      <h3
        style={{
          marginTop: '8px',
          marginBottom: '40px',
          padding: '0 20px 0 20px',
          lineHeight: '20px',
          color: '#86090f',
          fontSize: '1.17rem'
        }}
      >
        Ask your server for pricing
      </h3>
      {beerNames}
      <h4 className='menu-may-vary'>Menu selection may vary by location.</h4>
      <div className='line-break'></div>
      <p className='wine-restrictions'>{restrictions}</p>
    </div>
  )
}

export default Beers
