import React from 'react'
import ContentToggle from '../ContentToggle'
import MuiButton from '../MuiButton'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import SearchIcon from '@material-ui/icons/Search'
import { Link } from 'react-router-dom'

const Header = (props) => {
  const path = window.location.pathname
  return (
    <div className={path === '/' ? 'header' : 'content-header'}>
      <div className={path === '/' ? 'hide' : 'header-buttons'}>
        <MuiButton
          startIcon={<NavigateBeforeIcon />}
          onClick={() => props.goBack()}
          name='Back'
        />
        <Link to='/' style={{ textDecoration: 'none' }}>
          <MuiButton endIcon={<SearchIcon />} name='Menu Categories' />
        </Link>
      </div>
      {path === '/' ? (
        <>
          {/* <img className='logo' src={require('../../assets/images/logo.png')} alt='Texas de Brazil Logo'/> */}
          <h1 className='home-title'>
            Texas de Brazil <span>|</span> In-Store Menu
          </h1>
        </>
      ) : (
        <ContentToggle {...props} />
      )}
    </div>
  )
}

export default Header
