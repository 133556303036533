export const conceptInfo = [
  'In southern Brazil, local cowboys called gauchos, prepare legendary feasts in a tradition known as churrasco. They slow-roast meats over open flames and present an impressive display of seasonal greens, vegetable dishes and regional delicacies. The succulent meats are brought to each group’s table and carved in a show of skill and festive offering.',
  'At Texas de Brazil, we continue this treasured tradition. Treat yourself to a wide array of chef-crafted salads, roasted vegetables, imported cheeses and charcuterie. Enjoy house-baked cheese bread, garlic mashed potatoes and fried bananas. Turn your card to green, and expert carvers generously serve you sizzling beef, lamb, pork, chicken and Brazilian sausage. Simply turn your card to red to take a break. Liven up your meal by ordering our signature cocktail – the Caipirinha. And remember, our servers are always standing by to accommodate any request.',
  'Price does not include alcohol, featured items, desserts, beverages, tax or gratuity.',
  'When purchased with a full-price meal: children 2 years and under are complimentary, 3-5 years are $5.00 and 6-12 years are half price.',
  'All major credit cards accepted / No checks / No separate checks. No outside beverages, except up to two rare vintage wines (750 ml maximum) per party.',
  'Our continuous dining concept does not allow unused portions to be taken from the restaurant.',
  'Consuming raw or undercooked meats, poultry, seafood, shellfish or eggs may increase your risk of foodborne illness.',
  'Please refrain from leaving items on the table such as phones, hats, and/or bags. Under the churrasco style of dining there might be spills or splatters, by dining here you assume the risk of spillage on personal items.'
]

export const restrictions = {
  food: 'Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne illness. 2,000 calories a day is used for general nutrition advice, but calorie needs vary. Additional nutritional information available at texasdebrazil.com/nutrition or upon request.',
  wbtg: 'A 6oz glass of wine contains 109-152 calories. If we’re unable to present the listed vintage, we may have the next available vintage. Pricing may vary & does not include tax. 2,000 calories a day is used for general nutrition advice, but calorie needs vary. Additional nutritional information available at texasdebrazil.com/nutrition or upon request.',
  wine: 'A standard 750ml bottle of wine contains 461-642 calories. If we’re unable to present the listed vintage, we may have the next available vintage. Pricing may vary & does not include tax. 2,000 calories a day is used for general nutrition advice, but calorie needs vary. Additional nutritional information available at texasdebrazil.com/nutrition or upon request.',
  lfb: 'A magnum bottle of wine contains 960-1216 calories. If we’re unable to present the listed vintage, we may have the next available vintage. Pricing may vary & does not include tax. 2,000 calories a day is used for general nutrition advice, but calorie needs vary. Additional nutritional information available at texasdebrazil.com/nutrition or upon request.',
  general:
    'Pricing may vary & does not include tax. 2,000 calories a day is used for general nutrition advice, but calorie needs vary. Additional nutritional information available at texasdebrazil.com/nutrition or upon request.',
  port: 'Port is a Portuguese fortified wine typically served as dessert wine.',
  moscatel:
    "Moscatel de Setúbal is a treasured fortified wine produced in Portugal's Setúbal Peninsula."
}
