import React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'

// components imports
import Flights from '../Flights'
import FortifiedWine from '../FortifiedWine'
import DwAndM from '../DwAndM'
import Wine from '../Wine'
import Cocktails from '../Cocktails'
import WineByTheGlass from '../WineByTheGlass'
import Desserts from '../Desserts'
import BarApps from '../BarApps'
import Concept from '../Concept'
import Loading from '../Loading'
import Specials from '../Specials'
import NaBev from '../NaBev'
import Beers from '../Beers'

// import HomeAccordion from '../HomeAccordion'
import Home from '../Home'

const Router = (props) => {
  const path = window.location.pathname
  return (
    <div
      className={
        path === '/' ? 'home-main-container' : 'content-main-container'
      }
    >
      <Switch>
        <Route
          exact
          path='/our_concept'
          render={() =>
            props.conceptInfo.length > 0 ? <Concept {...props} /> : <Loading />
          }
        />
        <Route
          exact
          path='/flights'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <Flights
                {...props}
                title='Flights'
                subtitle='Ask your server for pricing'
              />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/fortified_wines'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <FortifiedWine
                {...props}
                title='Fortified Wines'
                subtitle='Ask your server for pricing'
              />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/dessert_wines_and_martinis'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <DwAndM
                {...props}
                title='Dessert Wines & Martinis'
                category='Dessert Wine & Martinis'
                subtitle='Ask your server for pricing and available vintages'
              />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/liquors'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <Wine
                {...props}
                title='Liquors'
                subtitle='Ask your server for pricing'
                category='Liquors'
              />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/large_format_bottles'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <Wine
                {...props}
                title='Large Format Bottles'
                category='Large Format Bottles'
                subtitle='Ask your server for pricing and available vintages'
              />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/red_wines'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <Wine
                {...props}
                title='Red Wines'
                subtitle='Ask your server for pricing and available vintages'
                category='Red Wine'
              />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/white_wines'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <Wine
                {...props}
                title='White Wines'
                subtitle='Ask your server for pricing and available vintages'
                category='White Wine'
              />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/champagne_and_sparkling'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <Wine
                {...props}
                title='Champagne & Sparkling'
                subtitle='Ask your server for pricing and available vintages'
                category='Champagne & Sparkling'
              />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/cocktails'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <Cocktails {...props} />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/wines_by_the_glass'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <WineByTheGlass
                {...props}
                title='Wines by the Glass'
                subtitle='Ask your server for available vintages'
              />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/desserts'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <Desserts {...props} title='Desserts' />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/bar_appetizers'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <BarApps {...props} title='Bar Appetizers' />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/beers'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <Beers {...props} title='Beers' />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/non_alcoholic_beverages'
          render={() =>
            props.mainMenuData.length > 0 ? (
              <NaBev
                {...props}
                title='Non-Alcoholic Beverages'
                subtitle='Ask your server for pricing'
              />
            ) : (
              <Loading />
            )
          }
        />
        <Route
          exact
          path='/happy_hour_specials'
          render={() =>
            props.specialsList.length > 0 ? (
              <Specials {...props} title='Specials' />
            ) : (
              <Loading />
            )
          }
        />
        <Route exact path='/' render={() => <Home {...props} />} />
      </Switch>
    </div>
  )
}

export default withRouter(Router)
