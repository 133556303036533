import React from 'react'

import WineSubSection from './WineSubSection'

const WineSection = (props) => {
  const sectionItems = props.wines.filter(
    (item) => item.section === props.title
  )
  // handles subsections
  const findSubSections = props.wines.map((item) => item.sub_section)
  const uniqueSubSections = new Set(findSubSections)
  const subSectionNames = [...uniqueSubSections]
  //handles wine data to create wine rows
  const subSections = subSectionNames.map((subSection, id) => {
    return (
      <WineSubSection
        key={id}
        title={subSection}
        wines={sectionItems.filter((item) => item.sub_section === subSection)}
      />
    )
  })

  // finds section descriptions - LFBS AND liquors
  const findDescription = props.wines.map((item) => item.description)
  const uniqueDescription = new Set(findDescription)
  const description = [...uniqueDescription]

  return (
    <div className='wine-section'>
      {description ? <h2>{props.title}</h2> : <h2>{props.title}</h2>}
      {description ? (
        <h4 className='wine-sub-section-description'>{description}</h4>
      ) : null}
      <div>{subSections}</div>
    </div>
  )
}

export default WineSection
