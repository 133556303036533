import React from 'react'

import GlassBottleSubSection from './GlassBottleSubSection'

const GlassBottleSection = (props) => {
  // handles subsections
  const findSubSections = props.wines.map((item) => item.sub_section)
  const uniqueSubSections = new Set(findSubSections)
  const subSectionNames = [...uniqueSubSections]

  //handles wine data to create wine subSections
  const subSections = subSectionNames.map((subSection, id) => {
    return (
      <GlassBottleSubSection
        key={id}
        title={subSection}
        wines={props.wines.filter((item) => item.sub_section === subSection)}
      />
    )
  })

  return <div className='wine-section'>{subSections}</div>
}

export default GlassBottleSection
