import React from 'react'

const FlightRow = (props) => {
  return (
    <tbody>
      <tr className='wine-row'>
        <td className='flight-name-description'>
          <p className='flight-name margin0'>{props.flight.name}</p>
          <p className='flight-description margin0'>
            {props.flight.description}
          </p>
          <p className='flight-cals'>{props.flight.vintage}</p>
        </td>
        {/* <td className='flight-price'>{props.flight.price}</td> */}
      </tr>
    </tbody>
  )
}

export default FlightRow
