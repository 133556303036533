export const categoryRoutes = {
  happy_hour_specials: 'Happy Hour Specials',
  bar_appetizers: 'Bar Appetizers',
  cocktails: 'Cocktails',
  wines_by_the_glass: 'Wines by the Glass',
  desserts: 'Desserts',
  our_concept: 'Our Concept',
  red_wines: 'Red Wines',
  white_wines: 'White Wines',
  champagne_and_sparkling: 'Champagne & Sparkling',
  liquors: 'Liquors',
  beers: 'Beers',
  non_alcoholic_beverages: 'Non-Alcoholic Beverages',
  flights: 'Flights',
  fortified_wines: 'Fortified Wines',
  dessert_wines_and_martinis: 'Dessert Wines & Martinis'
  // large_format_bottles: 'Large Format Bottles'
}

export const homeCategories = [
  {
    title: 'Top Categories',
    content: [
      'Happy Hour Specials',
      'Bar Appetizers',
      'Cocktails',
      'Wines by the Glass',
      'Desserts',
      'Our Concept'
    ]
  },
  {
    title: 'Other Categories',
    content: [
      'Red Wines',
      'White Wines',
      'Champagne & Sparkling',
      'Liquors',
      'Beers',
      'Non-Alcoholic Beverages',
      'Flights',
      'Fortified Wines',
      'Dessert Wines & Martinis'
      // 'Large Format Bottles'
    ]
  }
]
