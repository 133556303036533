import React from 'react'

const CocktailItem = (props) => {
  return (
    <div className='box-menu-item cocktail-item'>
      <h3>{props.cocktail.name}</h3>
      <p className='cocktail-description'>{props.cocktail.description}</p>
      <p style={{ color: '#86090F' }}>{props.cocktail.inventory_id}</p>
      {props.cocktail.price.length > 0 && <p>{props.cocktail.price}</p>}
    </div>
  )
}

export default CocktailItem
