import React from 'react'

import CocktailItem from './CocktailItem'

const CocktailSection = (props) => {
  const cocktails = props.cocktails.map((cocktail, id) => {
    return <CocktailItem key={id} cocktail={cocktail} />
  })

  return (
    <div className='cocktail-section'>
      {props.title ? (
        <h1 className='cocktail-section-title'>{props.title}</h1>
      ) : null}
      {props.price ? (
        <h2 className='cocktail-section-price' style={{ marginTop: '10px' }}>
          {props.price}
        </h2>
      ) : null}
      {cocktails}
    </div>
  )
}

export default CocktailSection
