import React from 'react'

import WineSection from './WineSection'

const Wine = (props) => {
  // find all menu items that match the props.title
  const categoryItems = props.mainMenuData.filter(
    (item) => item.category === props.category
  )
  // find all unique sections of the filtered menu items
  const findSections = categoryItems.map((item) => item.section)
  const uniqueSections = new Set(findSections)
  const sectionNames = [...uniqueSections]

  // generate wine sections for each unique section within the category
  const sections = sectionNames.map((section, id) => {
    return (
      <WineSection
        title={section}
        key={id}
        wines={categoryItems.filter((item) => item.section === section)}
      />
    )
  })
  // define potential restriction options
  const lfbsRestrictions = (
    <p className='wine-restrictions'>
      {props.restrictions && props.restrictions.lfb}
    </p>
  )
  const wineRestrictions = (
    <p className='wine-restrictions'>
      {props.restrictions && props.restrictions.wine}
    </p>
  )
  const restrictions = (
    <p className='wine-restrictions'>
      {props.restrictions && props.restrictions.general}
    </p>
  )

  return (
    <div className='main'>
      <h1 className='wine-category-title' style={{ marginBottom: '0px' }}>
        {props.title}
      </h1>
      <h3
        style={{
          marginTop: '8px',
          marginBottom: '40px',
          padding: '0 20px 0 20px',
          lineHeight: '20px',
          color: '#86090f',
          fontSize: '1.17rem'
        }}
      >
        {props.subtitle}
      </h3>
      {sections}
      <h4 className='menu-may-vary'>Menu selection may vary by location.</h4>
      <div className='line-break'></div>
      {props.title === 'Liquors'
        ? restrictions
        : props.title === 'Large Format Bottles'
        ? lfbsRestrictions
        : wineRestrictions}
    </div>
  )
}

export default Wine
