import React from 'react'

const WineRow = (props) => {
  console.log(props.wine)
  return (
    <tbody>
      <tr className='wine-row'>
        {props.wine.inventory_id ? (
          <td className='wine-inventory-id'>{props.wine.inventory_id}</td>
        ) : null}
        <td
          className='wine-name'
          style={
            props.wine.category === 'Liquors' ||
            props.wine.category === 'NA Beverage'
              ? { paddingLeft: '20px' }
              : null
          }
        >
          {props.wine.name}
        </td>
        {/* {props.wine.vintage ? (
          <td className='wine-vintage'>{props.wine.vintage}</td>
        ) : null} */}
        {props.wine.category === 'Wines by the Glass' ||
        props.wine.category === 'NA Beverage' ? (
          <td className='wine-price'>{props.wine.price}</td>
        ) : null}
      </tr>
    </tbody>
  )
}

export default WineRow
