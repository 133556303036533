import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';



const MuiButton = (props) => {

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.2)',
      backgroundColor: '#86090F',
      textTransform: 'none',
      fontSize: '16px',
      fontFamily: 'Futura',
      '&:hover': {
        backgroundColor: '#86090F',
      }
    }, 
  }));

  const classes = useStyles();

  return (
    <Button
      variant='contained'
      color='primary'
      style={props.style}
      className={classes.button}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      onClick={props.onClick}>
      {props.name}
    </Button>
  )
}

export default MuiButton;
